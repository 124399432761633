import React from 'react';

import { Switch, Route, useLocation } from 'react-router-dom';

import '@peakon/bedrock/css/grid/index.css';
import { NotFoundScreen } from '@peakon/shared/components/NotFoundScreen/NotFoundScreen';

import { withLazy } from '../../components/LazyLoaded';
import { RestrictedRoute } from '../../components/RestrictedRoute';
import { INTEGRATION_FACTORY } from '../../data/integrations';
import { DataSettingsMainPage } from '../AdminContainers/DataSettings/DataSettingsMainPage';
import { ValuesMainPage } from '../AdminContainers/Values/ValuesMainPage';
import Dashboard from '../DashboardContainer';
import { OAuthComplete } from '../Integrations/OAuthComplete/OAuthComplete';
import { SsoSurveyTransfer } from '../Schedules/transfers/SsoSurveyTransfer';

// @ts-expect-error TS(2322): Type 'null' is not assignable to type '((props: an... Remove this comment to see the full error message
const Account = withLazy(() => import('../Account'), { loading: null });
const Attributes = withLazy(() => import('../Attributes/Attributes'));
const Courses = withLazy(() => import('../Courses'));
const Actions = withLazy(() => import('../Actions'));
const AccessManagement = withLazy(() => import('../AccessManagement'));
const Configure = withLazy(() => import('../Configure/Configure'));
const CustomSenderDomain = withLazy(() => import('../CustomSenderDomain'));
const Employees = withLazy(() => import('../Employees'));
const EmployeesImport = withLazy(
  () => import('../EmployeesImport/EmployeesImport'),
);
const Improve = withLazy(() => import('../ImproveRoutes'));
const AdminImprove = withLazy(() => import('../ImproveRoutes/Admin'));
const CompanyAccount = withLazy(
  () => import('../CompanyAccount/CompanyAccount'),
);
const Integrations = withLazy(() => import('../Integrations/Integrations'));
const Integration = withLazy(() => import('../Integration/Integration'));
const Questions = withLazy(() => import('../Questions/Questions'));
const ExternalMetricsAdmin = withLazy(() => import('../ExternalMetricsAdmin'));
const Branding = withLazy(() => import('../Branding'));
const Schedules = withLazy(() => import('../Schedules/Schedules'));

export const Routes = () => {
  const location = useLocation();

  return (
    <Switch>
      <RestrictedRoute
        path="/transfer/survey/sso"
        component={SsoSurveyTransfer}
        rights={['response:read']}
        sessionRight={true}
        exact
      />

      <RestrictedRoute
        path="/oauth/slack/complete"
        render={(props) => <OAuthComplete {...props} service="slack" />}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/microsoft-teams/start"
        component={() => {
          window.location.href =
            INTEGRATION_FACTORY['microsoft-teams'].getConnectUrl(location);
          return null;
        }}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/oauth/microsoft-teams/complete"
        render={(props) => (
          <OAuthComplete {...props} service="microsoft-teams" />
        )}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/oauth/bamboohr/complete"
        render={(props) => <OAuthComplete {...props} service="bamboohr" />}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/workday/complete"
        render={(props) => <OAuthComplete {...props} service="workday" />}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/company/custom-domain"
        component={CustomSenderDomain}
        rights={['email:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/company"
        component={CompanyAccount}
        rights={['company:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/integrations/:integration/start"
        component={Integration}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/integrations/apps"
        component={Integrations}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/integrations/:integration"
        component={Integration}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/integrations"
        component={Integrations}
        rights={['application:admin']}
        sessionRight={true}
      />

      <RestrictedRoute
        path="/admin/attributes*"
        rights={['attribute:admin', 'segment:admin']}
        sessionRight={true}
        component={Attributes}
      />

      <RestrictedRoute
        path="/admin/employees/import*"
        rights={['employee:admin']}
        sessionRight={true}
        component={EmployeesImport}
      />

      <RestrictedRoute
        path="/admin/employees*"
        rights={[
          'employee:read:all',
          'employee:read:manager',
          'employee:read:context',
        ]}
        sessionRight={true}
        component={Employees}
      />

      <RestrictedRoute
        addOn={['actions', 'engagement']}
        path="/actions*"
        rights={['action:admin', 'action:read']}
        component={Actions}
      />

      <RestrictedRoute
        exact
        path="/admin"
        rights={[
          'employee:read',
          'attribute:admin',
          'segment:admin',
          'group:admin',
          'application:admin',
          'company:admin',
          'schedule:admin',
          'question:admin',
          'value:admin',
          'sensitive:admin',
        ]}
        sessionRight={true}
        component={Configure}
      />

      <Route path="/admin/external_metrics*" component={ExternalMetricsAdmin} />

      <RestrictedRoute
        path="/admin/branding*"
        rights={['branding:admin']}
        addOn="survey_multi_brand"
        component={Branding}
      />

      <RestrictedRoute
        path="/admin/data-settings/*"
        rights={['company:admin', 'sensitive:admin']}
        sessionRight={true}
        component={DataSettingsMainPage}
      />

      <RestrictedRoute
        path="/admin/questions*"
        rights={['question:admin']}
        component={Questions}
      />

      <RestrictedRoute
        path="/admin/values"
        rights={['value:admin']}
        sessionRight={true}
        component={ValuesMainPage}
      />

      <RestrictedRoute
        path={[
          '/admin/access/(groups|specialist)',
          '/admin/access/(groups|specialist)/:id',
        ]}
        rights={['group:admin']}
        sessionRight={true}
        component={AccessManagement}
      />

      <RestrictedRoute
        path="/admin/schedules*"
        rights={['schedule:read']}
        sessionRight={true}
        component={Schedules}
      />

      <Route path="/account*" component={Account} />
      <Route path="/courses*" component={Courses} />
      <Route path="/admin/improve*" component={AdminImprove} />
      <Route path="/improve*" component={Improve} />
      <Route path="/dashboard*" component={Dashboard} />
      <Route component={NotFoundScreen} />
    </Switch>
  );
};
